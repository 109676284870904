.main-container {
    .table-header-container {
        margin-bottom: 1.5rem;
    }
    .table-search-container {
        display: flex;
        margin-bottom: 1rem;
        .table-search-inner {
            display: flex;
            align-items: center;
            .search-input {
                display: block;
                width: 100%;
                padding: 0.275rem 0.45rem;
                font-size: 1rem;
                font-weight: 400;
                border-radius: 5px;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
            }
            .search-icon  {
                margin-left: 0.5rem;
                color: #4050b5;
            }
        }
    }
    .table-main-container {
        margin-bottom: 25px;
        border: 1px solid #E6E9EB !important;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        letter-spacing: 0.5px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
        box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    }

    .cards-main-container {
        .card-column {
            .card-column-actions {
                position: absolute;
                right: 10px;
                top: 10px;
                display: flex;
                flex-direction: column;
                .btn {
                    &:not(:last-child){
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }
}