@use '@angular/material' as mat;
@import './styles/base/base';
@import './styles/shared/shared';


@import './styles/account';
@import './styles/common';
@import './styles/table';
@import './styles/forms';
@import './styles/layout';
@import './styles/dashboard';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fak-wms-primary: mat.define-palette(mat.$indigo-palette);
$fak-wms-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fak-wms-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fak-wms-theme: mat.define-light-theme((
  color: (
    primary: $fak-wms-primary,
    accent: $fak-wms-accent,
    warn: $fak-wms-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fak-wms-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@font-face {
  font-family: 'Poppins-Regular';
  src: url("./assets/Poppins/Poppins-Regular.ttf") format('truetype');
  
}
h3{
  font-family: 'Poppins-Regular';
}
h1,h2,h3,h4,h5,a{
  margin:0
}
.heading {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  font-family: 'Poppins-Regular';
}
a{
  text-decoration: none;
}

// ----------------login form csss--------------
.login-container, .forgot-container{
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: white ;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) input{
    background-color: white !important;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 11px 0px 11px 5px;
    width: 97%;
    margin-top: 5px;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: rgb(255 255 255 / 42%);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #202224;
  font-weight: 500;
  font-size: 14px;
}
.mdc-text-field {
  padding: 0 0px 0px 0px;
}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  background: white;
}
.mdc-text-field .mdc-floating-label {
  top: 0%;
  transform: translateY(0%);
  pointer-events: none;
}
 .mat-mdc-raised-button.mat-mdc-button-base {
  height: 40px;
  border-radius: 8px;
}
.mat-mdc-raised-button:not(:disabled) {
  color: var(--mdc-protected-button-label-text-color, inherit);
  font-weight: 600;
}
}


.action-icon-box {
  display: flex;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}