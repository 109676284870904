// Header
$header-background-color: #FFFFFF;
$header-text-color: #333333;
// Sidebar
$sidebar-background-color: #F4F4F4;
$sidebar-menu-color: #666666;
$sidebar-icon-color: #999999;
$sidebar-active-item-background-color: #E6E6E6;
$sidebar-active-item-color: #E6E6E6;
$sidebar-active-item-icon-color: #E6E6E6;
// Table
$table-heading-color: #555555;
// Forms
$form-label-color: #777777;
$form-placeholder-color: #999999;
$form-submit-button-background-color: #007BFF;
$form-submit-button-text-color: #FFFFFF;

//Common
$light-color: #999999;

$upload-button-border: #2974F0;