@import '../shared/variables';

.d-flex{
    display: flex !important;
}

.w-100{
    width: 100%;
}

.justify-space-between{
    justify-content: space-between;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items-end{
    align-items: self-end!important;
}

.align-items-center {
    align-items: center;
}

.align-center {
    align-self: center !important;
}
.align-items-start{
    align-items: flex-start
}

.flex-col {
    flex-direction: column;
}

.mt-2 {
    margin-top: 1rem;
}

.ms-1 {
    margin-left: 0.5rem;
}

.m-1{
    margin: 1rem;
}

.m-2{
    margin: 2rem;
}

.m-3{
    margin: 3rem;
}


.p-1 {
    padding: 0.5rem !important;
}

.d-block {
    display: block;
}

.p-2 {
    padding: 1rem;
}

.p-3 {
    padding: 2rem;
}

.me-2 {
    margin-right: 1rem;
}

.mx-1{
   margin: 0 1rem;
}

.mx-2{
    margin: 0 2rem;
}
.me-2 {
    margin-right: 1rem;
}
.mx-3{
    margin: 0 3rem;
}
.my-1{
    margin: 1rem 0;
}

.my-2 {
    margin: 2rem 0;
}

.p-0 {
    padding: 0 !important;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.align-items-center {
    align-items: center;
}

.label-form {
    margin-bottom: 0.5rem;
    display: block;
}

.primary-icon-color {
    color: #2974F0;   
}

.position-relative {
    position: relative;
}

.cursor-pointer {
    cursor: pointer;
}

.font-light {
    color: $light-color;
}


.badge {
    display: inline-block;
    padding: 0.35rem 0.5rem;
    font-size: 14px;
    line-height: 1;
    color: #000000;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius:  10px;
    height: fit-content;
}

.text-bg-success {
    color: #fff ;
    background-color: #198754;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-edit {
    background-color: #2974F04D;
    color: #005FFB;
}

.btn-view {
    background-color: #3D42DF4D;
    color: #3D42DF;
}

.btn-delete {
    background-color: #FF0C004D;
    color: #FF0C00;
}

.btn-sm {
    padding: .25rem .25rem;
    font-size: .875rem;
    border-radius: .2rem;
}

.br-50 {
    border-radius: 50%;
}

.w-50 {
    width: 50%;
}

.font-xs {
    font-size: .5rem;
}

.font-sm {
    font-size: .75rem;
}

.object-fit-contain {
    object-fit: contain;
}

.text-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .h-100 {
    height: 100%;
  }

  .img-fluid {
    max-width: 100%;
    width: auto;
  }